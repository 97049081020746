import React from "react";

const FuturGame = ({ teamA, teamB, location, date, time, url }) => {
  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    // Set timezone to UTC to ensure consistent date handling
    options.timeZone = "UTC";
    return date.toLocaleDateString("en-US", options);
  }

  function formatTime(timeString) {
    const time = new Date("1970-01-01T" + timeString);
    const options = { hour: "numeric", minute: "numeric" };
    return time.toLocaleTimeString("en-US", options);
  }
  const formattedDate = formatDate(date);
  const formattedTime = formatTime(time);
  return (
    <div className='my-0 py-2 font-bold'>
      <div className='grid grid-cols-2 font-bold'>
        <div className='text-left top-0 space-y-1'>
          <p className='text-white'>{formattedDate}</p>
        </div>
        <div className='text-right bottom-0 space-y-1'>
          <p className='text-white'>{formattedTime}</p>
        </div>
      </div>
      <div className='rounded-lg shadow-lg bg-white'>
        <div className='grid grid-cols-2 mx-2 py-2 font-bold'>
          <div className='text-left top-0 space-y-2'>
            <p>{teamA}</p>
            <p>{teamB}</p>
          </div>
          <div className='text-right bottom-0 space-y-1'>
            <p>{location}</p>
            <button
              onClick={() => window.open(url, "_blank")}
              className='font-10 bg-secondary text-white font-bold py-1 px-2 rounded'
            >
              Tickets
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FuturGame;
