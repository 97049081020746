import React, { useState, useEffect } from "react";

const MainGame = ({
  name,
  teamALogo,
  teamBLogo,
  teamAName,
  teamBName,
  time,
}) => {
  const [isInProgress, setIsInProgress] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const startTime = new Date(time);
      const endTime = new Date(time);
      endTime.setHours(endTime.getHours() + 2);
      endTime.setMinutes(endTime.getMinutes() + 33);

      const currentTime = new Date();

      if (currentTime >= startTime && currentTime <= endTime) {
        setIsInProgress(true);
      } else {
        setIsInProgress(false);
      }
    }, 1000); // Update every second

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, [time]); // Run once on component mount

  return (
    <div className='text-white'>
      <div className='grid grid-cols-2 gap-5 text-center font-bold'>
        <div>
          <img
            className='rounded-lg shadow-lg'
            src={teamALogo}
            alt={teamAName}
          />
        </div>
        <div>
          <img
            className='rounded-lg shadow-lg'
            src={teamBLogo}
            alt={teamBName}
          />
        </div>
      </div>
      {isInProgress && (
        <div className='flex justify-center items-center mx-auto space-x-2 py-2 mt-2'>
          <p className='text-xs shrink'>
            <a
              target='_blank'
              href='https://www.nhl.com/scores'
              rel='noreferrer'
            >
              <b>{name}</b> game is in progress...
            </a>
          </p>
        </div>
      )}
    </div>
  );
};

export default MainGame;
